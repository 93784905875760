.Home_container{
  width: 100%;
  position: relative;
  
}
.Home_title{
  font-size:18px;
  font-weight:600;
   display: inline-block;

}
.Home_box{
  height:80px;
  width:100%;
}
.Home_line{
  height:1px; 
  width:100%;
  background:#E9EAEE;
  margin-top:31px;
  margin-bottom:29px;
}
.Home_line.subline{
  margin-bottom:22px;

}
.Home_line.buttonline{
  margin-top:0px;
  margin-bottom:26px;

}
.btnButtonLineOff{
  margin-top:21px;
border-bottom:3px solid #ffffff;
}
.btnButtonLine{
  margin-top:21px;
  border-bottom:3px solid #FEC009;

}
.Home_container_horizontal{
  display: flex;
  justify-content: space-between;
}
.Home_container_vertical{
  display: block;
}
.refresh_button{
    float: right;
    margin-top: -3px;
}
.submit_button{
  position: absolute;
  bottom: 0;
  width: 92%;
  margin-bottom: 10px;
  background-color: #fff;
}
.item_text{
  color: #FEC009;
}
.item_container{
  display: flex;
  align-items: center;
}
.chattxt{
  margin-top:2px;
  margin-left:0.5px;
}