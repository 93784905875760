body {
  margin: 0;
}

.login-button {
  background-color: #fec009;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 180px;
  height: 60px;
}
@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-weight: 200;
  src: url("./font/NanumBarunGothicLight.otf") format("opentype"),
    url("./font/NanumBarunGothicLight.ttf") format("truetype");
}
@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-weight: 400;
  src: url("./font/NanumBarunGothic.otf") format("opentype"),
    url("./font/NanumBarunGothic.ttf") format("truetype");
}
@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-weight: 600;
  src: url("./font/NanumBarunGothicBold.otf") format("opentype"),
    url("./font/NanumBarunGothicBold.ttf") format("truetype");
}
* {
  font-family: "NanumBarunGothic";
}
body {
  margin: 0px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-left:9px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
}

.page-link:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.active .page-link {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.disabled .page-link {
  color: #ccc;
  background-color: #fff;
  border-color: #ccc;
}
.active-page {
  font-weight: bold;
  color: #fec009;
}
section{
  padding: 10px 9px;
}
::placeholder {
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
